import * as React from "react";

interface DateInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onValue: (val: Date) => void;
}

export default function DateInput({ onValue, ...props }: DateInputProps) {
  // const styles: Record<string, React.CSSProperties> = {};

  const onchange = (val: string) => {
    onValue(new Date(val));
  };

  return (
    <input
      type="date"
      onChange={(ev) => {
        onchange(ev.target.value);
      }}
      {...props}
    />
  );
}
