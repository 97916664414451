import { Box } from "@mui/material";
import * as React from "react";

export default function Stat({
  title,
  figure,
  subtitle,
  color,
}: {
  title: string;
  figure: string;
  subtitle: string;
  color: string;
}) {
  const styles: Record<string, React.CSSProperties> = {
    container: {
      display: "inline-flex",
      flexDirection: "column",
      alignItems: "center",
    },
    title: {},
    figure: {
      fontSize: "300%",
      fontWeight: "bold",
    },
    subtitle: {},
  };

  return (
    <Box style={styles.container}>
      <div style={styles.title}>{title}</div>
      <div
        style={{
          ...styles.figure,
          ...{ color: color },
        }}
      >
        {figure}
      </div>
      <div
        style={{
          ...styles.subtitle,
          ...{ color: color },
        }}
      >
        {subtitle}
      </div>
    </Box>
  );
}
