import {
  Button,
  CircularProgress,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import { ContributionValue } from "components/contributions";
import Stat from "components/stat";
import * as React from "react";
import _ from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";

type WithProjectedValue = {
  projectedValue: number;
};

type ContributionValueWithProjectedValue = ContributionValue &
  WithProjectedValue;

export default function PerformanceResults() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [params] = useSearchParams();

  const [contributions, setContributions] = React.useState<
    Array<ContributionValueWithProjectedValue>
  >([]);

  const [actualValue, setActualValue] = React.useState<number>(0);
  //const [actualFutureValue, setActualFutureValue] = React.useState<number>(0);

  const [idealValue, setIdealValue] = React.useState<number>(0);
  const [idealFutureValue, setIdealFutureValue] = React.useState<number>(0);

  const [timeframe, setTimeframe] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useMemo(() => {
    const data = parseData(params);
    if (!data) {
      navigate("/performance");
      return;
    }

    setActualValue(data.v);
    setContributions(data.c);

    Promise.all(
      _.map(data.c, (c: ContributionValueWithProjectedValue) => {
        return fetch(indexDataURL(c?.date as Date))
          .then(jsonResponse)
          .then((d) => {
            const value = c.value as number;
            const price = +d.price;
            return {
              date: c.date,
              price: price,
              value: value,
              units: value / price,
            };
          });
      })
    )
      .then((results) => {
        console.log(results);
        const totalUnits = _.sum(_.map(results, (r) => r.units));
        const now = new Date();

        return fetch(indexDataURL(now))
          .then(jsonResponse)
          .then((d) => {
            const price = +d.price;
            const value = totalUnits * price;
            setIdealValue(value);

            setLoading(false);
          });
      })
      .catch(() => {});
  }, [params, navigate]);

  React.useEffect(() => {
    setIdealFutureValue(idealValue * 1.11 ** timeframe);
  }, [timeframe, idealValue]);

  const startYear = () => {
    const c = _.minBy(contributions, (c) => {
      return c.date;
    }) as ContributionValue;
    const d = c.date as Date;
    return d.toLocaleString("en-US", {
      month: "short",
      year: "numeric",
    });
  };

  const styles: Record<string, React.CSSProperties> = {
    container: {
      maxWidth: "480px",
      margin: "auto",
      paddingLeft: "20px",
      paddingRight: "20px",
      gap: "20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    figures: {
      justifyContent: "center",
      display: "flex",
      gap: "32px",
    },
    figuresControlOption: {
      width: "32px",
      paddingTop: "2px",
    },
    exposition: {
      display: "flex",
      flexDirection: "column",
      gap: "inherit",
    },
    buttons: {
      display: "flex",
      flexDirection: "column",
      gap: "inherit",
    },
    p: {
      textAlign: "left",
    },
  };

  return (
    <Box style={styles.container}>
      {loading ? (
        <CircularProgress />
      ) : idealValue / actualValue > 1.02 ? (
        <>
          <Box>You underperformed.</Box>

          <Box key="figures" style={styles.figures}>
            <Stat
              title="Current"
              figure={moneyFormatter.format(actualValue)}
              subtitle={startYear() + " - Now"}
              color={theme.palette.text.primary}
            />
            <Divider orientation="vertical" variant="middle" flexItem />
            <Stat
              title="Wealthmode"
              figure={moneyFormatter.format(idealFutureValue)}
              subtitle={moneyFormatter.format(idealFutureValue - actualValue)}
              color={
                idealValue - actualValue > 0
                  ? theme.palette.primary.main
                  : theme.palette.error.main
              }
            />
          </Box>

          <ToggleButtonGroup
            key="figureControls"
            size="small"
            color="info"
            value={"" + timeframe}
            exclusive
            onChange={(
              _e: React.MouseEvent<HTMLElement>,
              value: string | null
            ) => {
              if (value != null) {
                setTimeframe(+value);
              }
            }}
          >
            <ToggleButton value="0">
              <Box style={styles.figuresControlOption}>Now</Box>
            </ToggleButton>
            <ToggleButton value="10">
              <Box style={styles.figuresControlOption}>10Y</Box>
            </ToggleButton>
            <ToggleButton value="20">
              <Box style={styles.figuresControlOption}>20Y</Box>
            </ToggleButton>
          </ToggleButtonGroup>

          <Box key="exposition" style={styles.exposition}>
            {timeframe === 0 ? (
              <>
                <Box style={styles.p}>
                  Your balance of {moneyFormatterFull.format(actualValue)}{" "}
                  underperformed by{" "}
                  <b>{moneyFormatterFull.format(idealValue - actualValue)}</b>
                </Box>

                <Box style={styles.p}>
                  You would have had {moneyFormatterFull.format(idealValue)} if
                  you had invested in the US stock market.
                </Box>
              </>
            ) : (
              <Box style={styles.p}>
                Your net worth is projected to be{" "}
                <b>
                  {moneyFormatter.format(idealFutureValue)} <i>less</i>
                </b>{" "}
                than the market over {timeframe} years.
              </Box>
            )}

            <Divider />

            <Box style={styles.p}>
              Sign up for Wealthmode for free advice on how to improve your
              performance.
            </Box>
          </Box>
        </>
      ) : idealValue / actualValue < 0.98 ? (
        <>
          <Box>You beat the market!</Box>

          <Box component="h1">
            +{moneyFormatter.format(actualValue - idealValue)}
          </Box>

          <Box key="exposition" style={styles.exposition}>
            <Box style={styles.p}>
              Your balance of {moneyFormatterFull.format(actualValue)}{" "}
              outperformed the market by{" "}
              <b>{moneyFormatter.format(idealValue)}</b>!
            </Box>

            <Divider />

            <Box style={styles.p}>
              Sign up for Wealthmode to monitor your performance continuously to
              make sure you keep winning.
            </Box>
          </Box>
        </>
      ) : (
        <>
          <h1>You tied the market.</h1>
          <Box style={styles.p}>
            You pretty much tied the market! Either a weird coincidence or you
            invest in index funds.
          </Box>
          <Divider />
          <Box style={styles.p}>
            Sign up for Wealthmode to monitor your performance continuously.
          </Box>
        </>
      )}

      <Box key="buttons" style={styles.buttons}>
        <Button variant="contained">Sign Up</Button>
        <Button onClick={() => navigate("/performance")}>Reset</Button>
      </Box>
    </Box>
  );
}

const indexDataURL = (date: Date): string => {
  return `https://api.backend.us-e1.prod.wealthmode.co/historical/stocks/SPY/${isoDate(
    date
  )}`;
};

const jsonResponse = (r: Response) => {
  if (!r.ok) {
    throw new Error(
      "Historical data fetch error: " + r.statusText + " - " + r.body
    );
  }
  return r.json();
};

const isoDate = (d: Date): string => {
  return d.toISOString().split("T")[0];
};

const moneyFormatterFull = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumSignificantDigits: 3,
});

const moneyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumSignificantDigits: 3,
  notation: "compact",
});

function parseData(params: URLSearchParams) {
  const queryData = params.get("d");
  if (!queryData) {
    return false;
  }

  const decodedData = (() => {
    try {
      return atob(queryData);
    } catch {
      return null;
    }
  })();
  if (!decodedData) {
    return false;
  }

  const data = JSON.parse(decodedData);
  if (!data) {
    return false;
  }

  data.c = _.map(data.c, (c) => {
    return {
      value: c.value,
      date: new Date(c.date),
    };
  });

  return data;
}
