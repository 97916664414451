import { createTheme, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/root";

import "./App.css";
import "./index.css";
import Performance from "routes/performance";
import PerformanceResults from "routes/performance/results";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
    },
    {
      path: "/performance",
      element: <Performance />,
    },
    {
      path: "/performance/results",
      element: <PerformanceResults />,
    },
  ]);

  const theme = createTheme({
    palette: {
      primary: {
        50: "#67f0a2",
        100: "#49e68d",
        200: "#2dda78",
        300: "#28b766",
        400: "#219653",
        500: "#227e4a",
        600: "#216840",
        700: "#1f5235",
        800: "#1b3f2a",
        900: "#162c1f",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <React.StrictMode>
          <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            <center>
              <img
                src="/logo192.png"
                width="64"
                height="64"
                alt="Wealthmode"
              ></img>
            </center>
          </div>
          <RouterProvider router={router} />
        </React.StrictMode>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
