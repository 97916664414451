import * as React from "react";

export default function Prompt({
  question,
  children,
}: {
  question: string;
  children: React.ReactNode;
}) {
  const styles: Record<string, React.CSSProperties> = {
    container: {},
    section: {
      marginBottom: "4px",
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.section}>{question}</div>
      <div style={styles.section}>{children}</div>
    </div>
  );
}
