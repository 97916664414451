import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Contributions, { ContributionValue } from "components/contributions";
import MoneyInput from "components/money_input";
import Prompt from "components/prompt";
import _ from "lodash";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export default function Performance() {
  const navigate = useNavigate();

  const accountValue = React.useRef<number | null>(null);
  const contributions = React.useRef<Array<ContributionValue>>([]);

  const onContributionsChange = (values: Array<ContributionValue>) => {
    console.log("New contributions value");
    contributions.current = values;
  };

  const onAccountValueChange = (val: number | null) => {
    console.log("New account value");
    accountValue.current = val;
  };

  const submit = (event: React.FormEvent) => {
    const result = (function () {
      if (accountValue.current == null) {
        console.log("No account value");
        event.preventDefault();
        return false;
      }

      if (contributions.current.length === 0) {
        console.log("contributions missing");
        event.preventDefault();
        return false;
      }

      if (
        _.reduce(
          contributions.current,
          (err, cont) => {
            return err || cont.value === null || cont.date === null;
          },
          false
        )
      ) {
        console.log("contributions incomplete");
        return false;
      }

      const data = {
        c: contributions.current,
        v: accountValue.current,
      };

      navigate(`/performance/results?d=${btoa(JSON.stringify(data))}`);
    })();

    if (!result) {
      event.preventDefault();
    }
  };

  const styles: Record<string, React.CSSProperties> = {
    root: {
      gap: "20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

      textAlign: "center",
    },
    form: {
      gap: "20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

      textAlign: "center",
    },
  };
  return (
    <div style={styles.root}>
      <h1>Are you beating the market?</h1>
      <Box component="form" style={styles.form} onSubmit={submit}>
        <Prompt question={"Enter your account contribution amounts & dates."}>
          <Contributions onValue={onContributionsChange} />
        </Prompt>
        <Prompt question={"What is your total account balance today?"}>
          <MoneyInput
            required
            style={
              {
                textAlign: "center",
              } as React.CSSProperties
            }
            onValue={onAccountValueChange}
          />
        </Prompt>

        <Button type="submit" variant="contained">
          Calculate Performance
        </Button>
      </Box>
    </div>
  );
}
