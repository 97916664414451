import * as React from "react";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import { useTheme } from "@mui/material";

interface MoneyInputProps extends CurrencyInputProps {
  deposit?: boolean | null;
  onValue: (value: number | null) => void;
}

export default function MoneyInput({
  deposit,
  required,
  onValue,
  ...props
}: MoneyInputProps) {
  const theme = useTheme();

  const styles: Record<string, React.CSSProperties> = {
    deposit: {},
    withdrawal: {
      color: theme.palette.error.main,
    },
  };

  const { style, ...rest } = props;

  return (
    <CurrencyInput
      className="money"
      prefix={deposit == null ? "$" : deposit ? "+$" : "-$"}
      placeholder={deposit == null ? "$" : deposit ? "+$0" : "-$0"}
      style={{
        ...style,
        ...(deposit == null
          ? styles.deposit
          : deposit
          ? styles.deposit
          : styles.withdrawal),
      }}
      required={required}
      decimalsLimit={0}
      onValueChange={(value: string | undefined) => {
        if (typeof value === "undefined") {
          onValue(null);
        } else {
          onValue(deposit == null ? +value : deposit ? +value : -value);
        }
      }}
      {...rest}
    />
  );
}
